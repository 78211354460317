import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { StandRatingsStudentItem } from '@modules/types/graphql';

export type Entity = StandRatingsStudentItem | null;

@DecribeEntity('StandRatingsStudentItemEntity')
class StandRatingsStudentItemEntity extends BaseEntity {
    id: string
    fullName: string
    company: number
    platoonX: number
    score: number

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? ''
        this.fullName = entity?.fullName ?? ''
        this.company = entity?.company ?? 0
        this.platoonX = entity?.platoonX ?? 0
        this.score = entity?.score ?? 0
    }

    getInitials() {
        if (!this.fullName || this.fullName.length === 0) {
            return '-';
        }

        const words = this.fullName.split(' ');
        let initials = '';

        if (words[1]) {
            initials += `${words[1][0]}.`;
        }

        if (words[2]) {
            initials += `${words[2][0]}.`;
        }

        return `${words[0]} ${initials}`;
    }
}

export { StandRatingsStudentItemEntity };
