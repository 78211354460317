import { EntityList } from '@lib/entity';

import { StandCompanyRatingsItemEntity } from './stand-company-ratings-item-entity';

import type { Entity } from './stand-company-ratings-item-entity';

class StandCompanyRatingsItemEntityList extends EntityList<StandCompanyRatingsItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StandCompanyRatingsItemEntity, items, count);
    }
}

export { StandCompanyRatingsItemEntityList };
