type Items = any[] | null;

type Constructor<T> = new (...args: any[]) => T;

type Compare<U> = (a: U, b: U) => number;

class EntityList<T> {
    #entities: T[];
    #count: number;

    get entities(): T[] {
        return this.#entities;
    }

    get count(): number {
        return this.#count;
    }

    constructor(classEntity: Constructor<T>, items: Items, count?: number | null) {
        const Entity = classEntity;

        const trustItems = items ?? [];

        this.#count = count ?? trustItems.length;

        this.#entities = trustItems.map(item => new Entity(item));
    }

    *[Symbol.iterator](): Generator<T> {
        for (const key in this.#entities) {
            yield this.#entities[key];
        }
    }

    sortBy = <U>(field: string, compare: Compare<U>[] | Compare<U>): T[] => {
        const compareWrap = (compareFn: Compare<U>) => (a: T, b: T): number => {
            const fieldA = a[field];
            const fieldB = b[field];

            return compareFn(fieldA, fieldB);
        };

        let sorted = this.entities;

        if (Array.isArray(compare)) {
            compare.forEach(compareFn => {
                sorted = sorted.sort(compareWrap(compareFn));
            });
        } else {
            sorted.sort(compareWrap(compare));
        }

        return sorted;
    };
}

export { EntityList };
