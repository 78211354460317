import React from 'react';
import styled from 'styled-components';
import { red } from '@avangard/ui/colors';

import { CommonHelper } from '@helpers';
import { useTranslation } from '@lib/i18n';
import { colors } from '@modules/ui/core/colors';

type BestStudentProps = {
  fullName?: string;
  company?: number;
  platoonX?: number;
  score?: number;
};

const Root = styled.div`
  position: relative;
`;

const Name = styled.div`
  color: #fff;
  font-size: 55px;

  width: 800px;
`;

const About = styled.div`
  position: absolute;
  top: 185px;
  left: 25px;
  width: 400px;
  color: #fff;
  display: flex;
  gap: 60px;

  p {
    font-size: 25px;
    color: ${colors.gray};
  }

  span {
    color: ${colors.orange};
  }
`;

const Result = styled.div``;

const Count = styled.span`
  color: ${colors.orange};
  font-size: 65px;
  margin-right: 10px;
`;

const Label = styled.span`
  font-size: 20px;
  color: #fff;
`;

const Content = styled.div`
  position: absolute;
  top: 40px;
  left: 60px;

  display: flex;
  gap: 100px;
  align-items: center;
`;

const Divider = styled.div`
  height: 45px;
  width: 11px;
  background-color: ${colors.gray};
`;

const BestStudent = (props: BestStudentProps): React.ReactElement => {
  const { fullName, company, platoonX, score = 0 } = props;

  const { t } = useTranslation();

  const displayScore = CommonHelper.createCountFormatter(score, t('score'), false);

  return (
    <Root>
      <About>
        <p>
          {t('Рота')}: <span>{company}</span>
        </p>
        <p>
          {t('Взвод')}: <span>{platoonX}</span>
        </p>
      </About>
      <Content>
        <Name>{fullName}</Name>
        <Divider />
        <Result>
          <Count>{score}</Count>
          <Label>{displayScore}</Label>
        </Result>
      </Content>
    </Root>
  );
};

export { BestStudent };
