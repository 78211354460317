import { EntityList } from '@lib/entity';

import { StandRatingsStudentItemEntity } from './stand-ratings-student-item-entity';

import type { Entity } from './stand-ratings-student-item-entity';

class StandRatingsStudentItemEntityList extends EntityList<StandRatingsStudentItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StandRatingsStudentItemEntity, items, count);
    }
}

export { StandRatingsStudentItemEntityList };
