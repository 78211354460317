import { gql } from '@apollo/client';

export const GetStandCompanyRatingsQuery = gql`
    query GetStandCompanyRatings {
        getStandCompanyRatings {
            company
            students {
                id
                fullName
                company
                platoonX
                score
            }
        }
    }
`;
