const globalStyles = `
    @font-face {
        font-family: Exo2;
        font-weight: 400;
        font-display: swap;
        font-style: normal;
        src: url('/fonts/Exo2-Light.eot');
        src: url('/fonts/Exo2-Light.eot?#iefix') format("embedded-opentype"), url('/fonts/Exo2-Light.woff2') format("woff2"), url('/fonts/Exo2-Light.ttf') format("truetype")
    }
    @font-face {
      font-family: Vox;
      font-weight: 400;
      font-display: swap;
      font-style: normal;
      src: url('/fonts/vox_regular15.ttf');
  }
    
    html, body { height: 100%; }

    
    html {
        background: none!important;
user-select: none;
    }
    
    body {
        background: none!important;

        min-height: 3840px;
        height: 3840px;
        font-family: Vox, Exo2, Colibri, Arial, sans-serif; 

       
    }


 `;

export { globalStyles };
