export enum SortDirectionEnum {
    asc = 'asc',
    desc = 'desc',
}

class SortHelper {
    static sortBy(field: string, direction: SortDirectionEnum, array: any[]) {
        const equal = direction === SortDirectionEnum.asc ? 1 : -1;
        const unequal = direction === SortDirectionEnum.asc ? -1 : 1;

        const sorted = [...array].sort((current, next) => {
            const currentWeight = current[field] ?? 0;
            const nextWeight = next[field] ?? 0;

            return currentWeight > nextWeight ? equal : unequal;
        });

        return sorted;
    }
}

export { SortHelper };
