import { IndexPage } from '@pages/index';
import { BaseLayout } from '@modules/layout/templates';
import { DashboardPage } from '@pages/dashboard';
import { RatingsPage } from '@pages/ratings';
import { EmployeesPage } from '@pages/employees';

import type { RouteComponentProps, RedirectProps } from '@reach/router';

export enum RouteMenuLocationEnum {
  sidebar = 'sidebar',
}

export type Route = {
  name: string;
  title: string;
  path: string;
  Component: React.FunctionComponent<any>;
  LayoutComponent: React.FunctionComponent<any>;
  withBackIcon?: boolean;
  default?: boolean;
  children?: React.ReactNode;
};

export type ConfigRoute = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
  name: string;
};

const routes: ConfigRoute = {
  index: {
    name: 'index',
    title: 'Index',
    path: '/',
    Component: IndexPage,
    LayoutComponent: BaseLayout,
  },

  dashboard: {
    name: 'dashboard.index',
    title: 'Дашборд',
    path: '/dashboard',
    Component: DashboardPage,
    LayoutComponent: BaseLayout,
  },

  ratings: {
    name: 'ratings.index',
    title: 'Рейтинг',
    path: '/ratings',
    Component: RatingsPage,
    LayoutComponent: BaseLayout,
  },

  employees: {
    name: 'employees.index',
    title: 'Преподаватели',
    path: '/employees',
    Component: EmployeesPage,
    LayoutComponent: BaseLayout,
  },
};

const redirects: RedirectRoute[] = [
  {
    name: 'default',
    from: '/',
    to: routes.index.path,
    default: true,
  },
];

export { routes, redirects };
