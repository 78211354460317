import React from 'react';

import { useCurrentLanguage } from './use-current-language';

import ru from '@translations/ru.json';
import en from '@translations/en.json';
import { Translation } from '@modules/types/graphql';

export const useTranslation = () => {
  const language = useCurrentLanguage();

  const t = (key: keyof typeof ru) => {
    const translations = {
      ru,
      en,
    };

    return translations[language][key] ?? key;
  };

  const getTranslation = (translations?: Array<Translation | null>) => {
    return (translations || []).find(item => item?.locale === language)?.value || '';
  };

  return {
    t,
    language,
    getTranslation,
  };
};
