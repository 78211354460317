import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { white } from '@avangard/ui/colors';

type LoaderProps = {
  className?: string;
  color?: 'primary' | 'secondary' | 'inherit';
  size?: number | string;
};

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Loader = (props: LoaderProps) => (
  <Root {...props}>
    <CircularProgress size={props.size} color={props.color} />
  </Root>
);

export { Loader };
