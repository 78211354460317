import { gql } from '@apollo/client';

export const GetSDBInformationQuery = gql`
  query GetSDBInformation {
    getSDBInformation {
      date {
        date
        time
      }
      weather {
        temp
        icon
        conditionSlug
        condition
      }
      students
      companiesRating {
        company
        score
      }
      bestStudent {
        id
        fullName
        company
        platoonX
        score
      }
      lap {
        from
        to
      }
    }
  }
`;
