export const lightBlueGray = {
  50: '#A4D7FF',
  60: '#87B1D3',
  80: '#7491A3',
  90: '#425668',
  100: '#4C535C',
};

export const darkBlue = {
  50: '#0a0a8d',
};

export const colors = {
  gray: '#a7bed3',
  lightGreenBlue: '#00E3EF',
  orange: '#E95D19',
  orangeWithOpacity: 'rgba(249, 92, 50, 0.4)',
  darkBlue: '#1A1F23',
};
