import Cookie from 'js-cookie';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RestLink } from 'apollo-link-rest';

import { GRAPHQL_URL, REST_URL } from '@config/environment';
import { getCurrentLanguage } from '@lib/i18n';
import { SystemHelper } from '@helpers';

import type {
  ApolloClientOptions as BaseApolloClientOptions,
  NormalizedCacheObject,
} from '@apollo/client';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const isDev = SystemHelper.isDev();

const createApolloClient = (options: ApolloClientOptions = {}) => {
  const graphqlUri = GRAPHQL_URL;
  const restUri = REST_URL;

  const httpLink = new HttpLink({ uri: graphqlUri });

  const authHttpLink = setContext((_, prevContext) => {
    const headers = { ...prevContext.headers };

    const token = Cookie.get('accessToken');
    const language = getCurrentLanguage(window.location.search);

    const context = {
      ...prevContext,
      headers: {
        ...headers,
        Language: language,
        authorization: token ? `Bearer ${token}` : null,
      },
    };

    return context;
  });

  const restLink = new RestLink({
    uri: restUri,
    bodySerializers: {
      fileEncode: (data: any, headers: Headers) => {
        const formData = new FormData();

        formData.append('file', data, data.name);

        return { body: formData, headers };
      },
    },
  });

  // TODO: add to ApolloLink.from when backend can resolve it
  const batchHttpLink = new BatchHttpLink({
    uri: graphqlUri,
    batchMax: 6,
  });

  const link = ApolloLink.from([authHttpLink, restLink, httpLink]);

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: isDev,
    ...options,
  });

  return apolloClient;
};

export { createApolloClient };
