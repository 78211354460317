import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { StandEmployeesItem, FileInfo, Translation } from '@modules/types/graphql';

export type Entity = StandEmployeesItem | null;

@DecribeEntity('StandEmployeesItemEntity')
class StandEmployeesItemEntity extends BaseEntity {
  id: string;
  fullName: string;
  specialization: string;
  biography: string;
  photo: FileInfo;
  biographyTranslations: Array<Translation | null>;
  specializationTranslations: Array<Translation | null>;

  constructor(entity: Entity) {
    super(entity);

    this.id = entity?.id ?? '';
    this.fullName = entity?.fullName ?? '';
    this.specialization = entity?.specialization ?? '';
    this.biography = entity?.biography ?? '';
    this.photo = entity?.photo ?? { croppedThumbnail: '' };
    this.biographyTranslations = entity?.biographyTranslations ?? [];
    this.biographyTranslations = entity?.biographyTranslations ?? [];
  }
}

export { StandEmployeesItemEntity };
