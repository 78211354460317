import React from 'react';
import styled from 'styled-components';

import { Loader, Title } from '@modules/layout/moleculas';
import { StandEmployeesItemEntity } from '@modules/employees/entities';
import { useEmployees } from '@modules/employees/hooks';
import { EmployeeDetailed, EmployeesList } from '@modules/employees/organisms';
import { useTranslation } from '@lib/i18n';
import { StandCard, StandTopbar } from '@modules/shared/organisms';
import { useSdbInformation } from '@modules/dashboard/hooks';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 125px;

  & > * {
    flex: 0 0 auto;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }
`;

const EmployeesPage = (): React.ReactElement => {
  const { employees, loading: employeesLoading } = useEmployees({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  const { sdbInformation, loading: sdbInformationLoading } = useSdbInformation({
    fetchPolicy: 'network-only',
  });
  const [activeEmployee, setActiveEmployee] = React.useState<StandEmployeesItemEntity | undefined>(
    undefined,
  );

  const setActive = (employee: StandEmployeesItemEntity) => (): void => setActiveEmployee(employee);

  React.useEffect(() => {
    const firstEmployee = employees?.[0];

    if (firstEmployee) {
      setActiveEmployee(firstEmployee);
    }
  }, [employees]);

  if (employeesLoading || sdbInformationLoading) {
    return <Loader size={512} />;
  }

  return (
    <Root>
      <StandTopbar sdbInformation={sdbInformation} />

      <StandCard hideLogo version={1} title={t('Преподавательский состав')}>
        <EmployeeDetailed employee={activeEmployee} />
      </StandCard>

      <StandCard version={4}>
        <EmployeesList employees={employees} active={activeEmployee} setActive={setActive} />
      </StandCard>
    </Root>
  );
};

export { EmployeesPage };
