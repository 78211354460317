import { QueryHookOptions, useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { SdbInformationEntity } from '@modules/dashboard/entities';
import { GetSDBInformationQuery } from '@modules/dashboard/graphql';

import type { GetSdbInformationQueryType } from '@modules/types/graphql';

const useSdbInformation = (options: QueryHookOptions<GetSdbInformationQueryType> = {}) => {
  const queryResult = useQuery<GetSdbInformationQueryType>(GetSDBInformationQuery, options);

  const sdbInformation = useEntity(
    () => new SdbInformationEntity(queryResult.data?.getSDBInformation ?? null),
    [queryResult.data],
  );

  const result = {
    sdbInformation,
    ...queryResult,
  };

  return result;
};

export { useSdbInformation };
