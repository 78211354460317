import styled from 'styled-components';

import { SdbInformationEntity } from '@modules/dashboard/entities';
import { WeatherIcon } from '@modules/dashboard/atoms';

type DashboardCardProps = {
    sdbInformation: SdbInformationEntity;
};

const Root = styled.div`
    display: flex;
    padding: 100px 0;
    justify-content: space-between;
    align-items: start;
`;

export const TimeWrapper = styled.div`
    position: relative;

    display: flex;
    align-items: center;

    span {
        position: absolute;

        font-size: 128px;
        color: #fff;
        font-weight: bold;
        top: 0;
        right: 52px;
    }
`;

export const WeatherWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    span {
        position: absolute;
        color: #fff;
        font-size: 128px;
        font-weight: bold;
        left: 52px;
    }

    img:last-child {
        position: absolute;
        right: 36px;
        width: 144px;
        height: 144px;
    }
`;

const StandTopbar = (props: DashboardCardProps): React.ReactElement => {
    const { sdbInformation } = props;

    return (
        <Root>
            {/* <TimeWrapper>
        <img src='/images/ui/time.svg' alt='' />
        <span>{sdbInformation.date?.time}</span>
      </TimeWrapper>
      <img src='/images/ui/lines.svg' alt='' />
      <WeatherWrapper>
        <img src='/images/ui/weather.svg' alt='' />
        <span>{sdbInformation.weather?.temp} с°</span>

        <WeatherIcon slug={sdbInformation.weather?.conditionSlug} />
      </WeatherWrapper> */}
        </Root>
    );
};

export { StandTopbar };
