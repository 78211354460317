import React from 'react';
import styled from 'styled-components';
import { red } from '@avangard/ui/colors';

import { CommonHelper } from '@helpers';
import { colors, darkBlue } from '@modules/ui/core/colors';
import { useTranslation } from '@lib/i18n';

type CompaniesRatingItemProps = {
    company?: number;
    score?: number;
    children?: React.ReactElement;
};

const Root = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    font-size: 0.8em;

    gap: 140px;
`;

const Company = styled.div`
    margin-right: 20px;
    width: 292px;

    color: #fff;

    font-size: 40px;

    span {
        color: ${colors.lightGreenBlue};
        margin-right: 30px;
        font-size: 55px;
    }
`;

const Score = styled.div`
    min-width: 250px;

    font-size: 20px;

    color: #fff;

    span {
        margin-right: 10px;

        color: ${colors.orange};
        font-size: 55px;
    }
`;

const Divider = styled.div`
    background: ${colors.gray};
    height: 39px;
    width: 11px;
`;

const CompaniesRatingItem = (props: CompaniesRatingItemProps): React.ReactElement => {
    const { company = 0, score = 0, children } = props;

    const { t } = useTranslation();

    const displayScore = CommonHelper.createCountFormatter(score, t('score'), false);

    return (
        <Root>
            <Company>
                <span>{company}</span> {t('рота')}
            </Company>

            <Score>
                <span>{score}</span> {displayScore}
            </Score>

            <Divider />

            {children}
        </Root>
    );
};

export { CompaniesRatingItem };
