import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { StandSummaryCompanyRatingsItem } from '@modules/types/graphql';

export type Entity = StandSummaryCompanyRatingsItem | null;

@DecribeEntity('StandSummaryCompanyRatingsItemEntity')
class StandSummaryCompanyRatingsItemEntity extends BaseEntity {
    company: number
    score: number

    constructor(entity: Entity) {
        super(entity);

        this.company = entity?.company ?? 0
        this.score = entity?.score ?? 0
    }
}

export { StandSummaryCompanyRatingsItemEntity };
