import React from 'react';
import { createHistory, LocationProvider, Router } from '@reach/router';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider, GlobalStyles } from '@avangard/ui/theme';

import { createApolloClient } from '@lib/apollo';
import { renderRoutes, renderRedirects } from '@lib/routing';
import { routes as configRoutes, redirects as configRedirects } from '@config/routes';
import { globalStyles } from '@modules/layout/styled';

const history = createHistory(window as any);
const apolloClient = createApolloClient();

const useSnackbarStyles = makeStyles({
  variantInfo: {
    fontSize: '1.4rem',
    whiteSpace: 'pre-line',
  },
  variantSuccess: {
    fontSize: '1.4rem',
    whiteSpace: 'pre-line',
  },
  variantWarning: {
    fontSize: '1.4rem',
    whiteSpace: 'pre-line',
  },
  variantError: {
    fontSize: '1.4rem',
    whiteSpace: 'pre-line',
  },
});

const App = (): React.ReactElement => {
  const routes = Object.values(configRoutes);
  const redirects = Object.values(configRedirects);

  const snackbarClasses = useSnackbarStyles();

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider>
        <SnackbarProvider
          dense
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={snackbarClasses}
        >
          <GlobalStyles styles={globalStyles} />

          <LocationProvider history={history}>
            <Router basepath='/'>
              {renderRoutes(routes)}
              {renderRedirects(redirects)}
            </Router>
          </LocationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export { App };
