import { QueryHookOptions, useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { StandEmployeesItemEntityList } from '@modules/employees/entities';
import { GetStandEmployeesQuery } from '@modules/employees/graphql';

import type { GetStandEmployeesQueryType } from '@modules/types/graphql';

const useEmployees = (options: QueryHookOptions<GetStandEmployeesQueryType> = {}) => {
  const queryResult = useQuery<GetStandEmployeesQueryType>(GetStandEmployeesQuery, options);

  const { entities: employees, count } = useEntity(
    () =>
      new StandEmployeesItemEntityList(
        queryResult.data?.getStandEmployees ?? null,
        queryResult.data?.getStandEmployees?.length,
      ),
    [queryResult.data],
  );

  const result = {
    employees,
    count,
    ...queryResult,
  };

  return result;
};

export { useEmployees };
