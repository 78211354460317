import React from 'react';
import styled, { css } from 'styled-components';
import { colors, lightBlueGray } from '@modules/ui/core/colors';

type CompaniesRatingItemBarProps = {
  score?: number;
  maxScore?: number;
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const Line = css`
  height: 32px;
  width: 10px;
  flex: 0 0 auto;
  display: block;
`;

const LightLine = styled.div`
  ${Line}
  background: ${colors.orange};
`;

const DarkLine = styled.div`
  ${Line}
  background: ${colors.orangeWithOpacity};
`;

const CompaniesRatingItemBar = React.memo(
  (props: CompaniesRatingItemBarProps): React.ReactElement => {
    const { score = 0, maxScore = 0 } = props;
    const percent = (score * 100) / (maxScore || 1);

    const maxNumberLines = 72;
    const currentNumberLines = ((maxNumberLines * percent) / 100).toFixed(0) ?? 0;
    const arrayForRender = new Array(+currentNumberLines).fill(0);

    const darkArrayForRender = new Array(maxNumberLines - +currentNumberLines).fill(0);

    return (
      <Root>
        {arrayForRender.map((item, index) => (
          <LightLine key={index} />
        ))}
        {darkArrayForRender.map((item, index) => (
          <DarkLine key={index} />
        ))}
      </Root>
    );
  },
);

export { CompaniesRatingItemBar };
