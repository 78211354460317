import React from 'react';
import styled from 'styled-components';

import { StandCard } from '@modules/shared/organisms';
import { SdbInformationEntity } from '@modules/dashboard/entities';
import { Logo } from '@modules/dashboard/moleculas';
import { CurrentLap } from '@modules/dashboard/organisms';
import {} from '@modules/shared/organisms';

type DashboardTopProps = {
  sdbInformation: SdbInformationEntity;
  icon: React.ReactElement;
  children?: React.ReactElement;
  gradientBorder?: boolean;
  empty?: boolean;
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const DashboardTop = (props: DashboardTopProps): React.ReactElement => {
  const { sdbInformation } = props;

  return (
    <Root>
      {/* <StandCard empty={!sdbInformation.students || !sdbInformation.date || !sdbInformation.lap}>
        <CurrentLap
          students={sdbInformation.students}
          date={sdbInformation.date}
          lap={sdbInformation.lap}
        />
      </StandCard> */}

      {/* <Logo /> */}
    </Root>
  );
};

export { DashboardTop };
