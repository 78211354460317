import React from 'react';
import styled, { css } from 'styled-components';

import { colors, darkBlue, lightBlueGray } from '@modules/ui/core/colors';

import type { StandEmployeesItemEntity } from '@modules/employees/entities';
import { useTranslation } from '@lib/i18n';

type EmployeesListProps = {
    employees: StandEmployeesItemEntity[];
    active?: StandEmployeesItemEntity;
    setActive: (employee: StandEmployeesItemEntity) => () => void;
};

const Root = styled.div``;

const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 100px;
`;

const Body = styled.div`
    max-height: 1200px;
    overflow-y: scroll;

    padding: 50px 100px;

    ::-webkit-scrollbar {
        width: 36px;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: ${colors.orange};
    }
`;

const HeaderItem = styled.div`
    font-size: 45px;
    color: ${colors.orange};
    width: 834px;
`;

const Row = styled.div<{ active?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;

    color: ${({ active }) => (active ? '#ccc' : '#fff')};
    margin-bottom: 40px;
`;

const Cell = styled.div`
    font-size: 40px;
`;

const EmployeesList = (props: EmployeesListProps): React.ReactElement => {
    const { employees, active, setActive } = props;

    const { t, getTranslation } = useTranslation();
    console.log('employees', employees);
    return (
        <Root>
            <Header>
                <HeaderItem>{t('Имя')}</HeaderItem>
                <HeaderItem>{t('Должность')}</HeaderItem>
            </Header>
            <Body>
                {employees.map(item => (
                    <Row active={active?.id === item.id} onClick={setActive(item)} key={item.id}>
                        <Cell>{item.fullName}</Cell>
                        <Cell key={item.id + item.specialization}>
                            {getTranslation(item.specializationTranslations)}
                        </Cell>
                    </Row>
                ))}
            </Body>
        </Root>
    );
};

export { EmployeesList };
