import React from 'react';
import { Redirect } from '@reach/router';

import type { RedirectRoute } from '@config/routes';

const renderRedirects = (redirects: RedirectRoute[]) => {
    const rendered = redirects.map(redirect => (
        <Redirect key={redirect.name} noThrow {...redirect} />
    ));

    return rendered;
};

export { renderRedirects };
