import React from 'react';
import styled from 'styled-components';

import { colors, lightBlueGray } from '@modules/ui/core/colors';

import { StandCard } from '@modules/shared/organisms';

import type { StandEmployeesItemEntity } from '@modules/employees/entities';
import { useTranslation } from '@lib/i18n';

type EmployeeDetailedProps = {
    employee?: StandEmployeesItemEntity;
};

const Root = styled.div`
    padding: 20px 80px;

    display: flex;
    gap: 100px;
`;

const Name = styled.div`
    font-size: 45px;
    color: #fff;
    width: 292px;
`;

const Bio = styled.div`
    font-size: 30px;
    color: #fff;
    padding: 0 20px;
    overflow-y: scroll;
    max-height: 600px;
    ::-webkit-scrollbar {
        width: 36px;
    }
    ::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.orange};
        border-radius: 0;
    }
`;

const EmployeeDetailed = (props: EmployeeDetailedProps): React.ReactElement | null => {
    const { employee } = props;

    const { getTranslation } = useTranslation();

    return (
        <Root>
            <div>
                <img
                    width={350}
                    height={380}
                    src={employee?.photo?.croppedThumbnail ?? ''}
                    alt=''
                />

                <Name>{employee?.fullName}</Name>
            </div>

            <Bio>{getTranslation(employee?.biographyTranslations)}</Bio>
        </Root>
    );
};

export { EmployeeDetailed };
