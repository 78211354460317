import { gql } from '@apollo/client';

export const GetStandEmployeesQuery = gql`
  query GetStandEmployees {
    getStandEmployees {
      id
      fullName
      specialization
      specializationTranslations {
        locale
        value
      }
      biography
      biographyTranslations {
        locale
        value
      }
      photo {
        croppedThumbnail
      }
    }
  }
`;
