import React from 'react';
import styled from 'styled-components';

import { Loader } from '@modules/layout/moleculas';
import { useDetailedCompanyRatings } from '@modules/ratings/hooks';
import { DetailedCompanyRatingsItem } from '@modules/ratings/organisms';
import { useTranslation } from '@lib/i18n';
import { StandCard } from '@modules/shared/organisms';

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 60px;
  width: 1880px;
  row-gap: 60px;
`;

const DetailedCompanyRatings = (): React.ReactElement => {
  const {
    detailedCompanyRatings,
    loading: detailedCompanyRatingsLoading,
  } = useDetailedCompanyRatings({ fetchPolicy: 'network-only' });

  const { t } = useTranslation();

  const renderList = React.useMemo(() => {
    if (detailedCompanyRatingsLoading) {
      return <Loader size={40} />;
    }

    return detailedCompanyRatings.map((detailedCompanyRatingsItem, index) => (
      <DetailedCompanyRatingsItem
        key={index}
        company={detailedCompanyRatingsItem.company}
        students={detailedCompanyRatingsItem.students}
      />
    ));
  }, [detailedCompanyRatingsLoading]);

  return (
    <StandCard title={t('Рейтинг по ротам')} version={2}>
      <List>{renderList}</List>
    </StandCard>
  );
};

export { DetailedCompanyRatings };
