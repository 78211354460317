import React from 'react';
import styled from 'styled-components';

import { BaseLayoutProvider } from './context';

type BaseLayoutProps = {
    pageTitle: string;
    children: React.ReactNode;
    withBackIcon?: boolean;
};

const Root = styled.main`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
`;

const Main = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const BaseLayout = (props: BaseLayoutProps): React.ReactElement => {
    const { pageTitle: pageTitleProps, children } = props;

    return (
        <BaseLayoutProvider pageTitle={pageTitleProps}>
            <Root>
                <Main>
                    {children}
                </Main>
            </Root>
        </BaseLayoutProvider>
    );
};

export { BaseLayout };
