import { QueryHookOptions, useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { StandRatingsStudentItemEntityList } from '@modules/ratings/entities';
import { GetStandTopStudentsRatingsQuery } from '@modules/ratings/graphql';

import type { GetStandTopStudentsRatingsQueryType } from '@modules/types/graphql';

const useTopStudentsRatings = (options: QueryHookOptions<GetStandTopStudentsRatingsQueryType> = {}) => {
    const queryResult = useQuery<GetStandTopStudentsRatingsQueryType>(GetStandTopStudentsRatingsQuery, options);

    const { entities: topStudentsRatings, count } = useEntity(
        () =>
            new StandRatingsStudentItemEntityList(
                queryResult.data?.getStandTopStudentsRatings ?? null,
                queryResult.data?.getStandTopStudentsRatings?.length,
            ),
        [queryResult.data],
    );

    const result = {
        topStudentsRatings,
        topStudentsRatingsExists: count !== 0,
        count,
        ...queryResult,
    };

    return result;
};

export { useTopStudentsRatings };
