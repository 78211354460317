import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import type { Date } from '@modules/dashboard/entities';

const getDayOfWeek = (date?: Date | null, formatString = 'EEEE', language?: string) => {
    if (!date) {
        return '';
    }

    try {
        const currentDate = new Date(date.date);
        const dayOfWeek = format(currentDate, formatString, {
            locale: (language || 'ru') === 'ru' ? ru : undefined,
        });

        return dayOfWeek;
    } catch {
        return '';
    }
};

export { getDayOfWeek };
