import React from 'react';
import styled  from 'styled-components';

import { lightBlueGray } from '@modules/ui/core/colors';

type TitleProps = {
    children: React.ReactElement | string
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${lightBlueGray[50]};
  align-items: center;
  width: 100%;
  font-size: 4em;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
`;

const Line = styled.div`
  content: '';
  display: flex;
  align-items: center;
  height: 4px;
  margin-top: 16px;
  width: 100%;
  max-width: 500px;
  position: relative;

  &:before, &:after {
    content: '';
    flex: 1 1 auto;
    display: block;
    width: 50%;
    height: 100%;
  }

  &:before {
    background: linear-gradient(to left, ${lightBlueGray[50]}, ${lightBlueGray[50]}, rgba(0, 0, 0, 0));;
  }

  &:after {
    background: linear-gradient(to right, ${lightBlueGray[50]}, ${lightBlueGray[50]}, rgba(0, 0, 0, 0));;
  }
`;

const Title = (props: TitleProps): React.ReactElement => {
    const { children } = props;

    return (
        <Root>
            {children}

            <Line />
        </Root>
    );
};

export { Title };
