import React from 'react';
import styled from 'styled-components';

type DashboardCardProps = {
  children?: React.ReactElement | React.ReactElement[];
  title?: string;
  version: number;
  hideLogo?: boolean;
};

const Root = styled.div<{ version: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 2px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font-size: 3.4em;
  background-image: url(/images/ui/card-v${({ version }) => version}.svg);
  background-repeat: no-repeat;
  height: ${({ version }) =>
    version === 1 ? 1001 : version === 2 ? 1228 : version === 3 ? 917 : 1568}px;
  overflow-y: scroll;
  position: relative;
`;

const BackButton = styled.div`
  background-image: url(/images/ui/back.svg);
  width: 117px;
  height: 117px;
  position: absolute;

  top: 40px;
  left: 120px;
`;

const Content = styled.div<{ version: number }>`
  position: absolute;
  top: ${({ version }) => (version === 3 ? 140 : version === 4 ? 100 : 220)}px;
  left: 25px;
`;

const Title = styled.div<{ isHeader: boolean }>`
  position: absolute;
  top: 55px;
  left: ${({ isHeader }) => (isHeader ? 400 : 120)}px;
  font-size: 65px;
  color: #fff;
`;

const LogoWrapper = styled.div`
  position: absolute;

  top: 40px;
  left: 1320px;

  img {
    width: 132px;
    height: 124px;
  }
`;

const Languages = styled.div`
  position: absolute;
  top: 40px;
  left: 1600px;

  display: flex;
  gap: 25px;
`;

const StandCard = (props: DashboardCardProps): React.ReactElement => {
  const { title, children, version, hideLogo } = props;

  const isHeader = version === 1;

  return (
    <Root version={version}>
      {isHeader && (
        <>
          {/* <BackButton /> */}
          {!hideLogo && (
            <LogoWrapper>
              <img src='/images/star.gif' alt='Star logo' />
            </LogoWrapper>
          )}

          {/* <Languages>
            <img src='/images/ui/en-btn.svg' alt='en' />
            <img src='/images/ui/ru-btn.svg' alt='ru' />
          </Languages> */}
        </>
      )}

      <Title isHeader={isHeader}>{title}</Title>
      <Content version={version}>{children}</Content>
    </Root>
  );
};

export { StandCard };
