class StringHelper {
    static capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static getShortName(string: string) {
        const [first, second, third] = string.split(' ');

        let str = `${first}`;

        if (second) {
            str += ` ${second[0]}.`;
        }

        if (third) {
            str += ` ${third[0]}.`;
        }

        return str;
    }
}

export { StringHelper };
