import React from 'react';
import styled from 'styled-components';

import { colors, darkBlue, lightBlueGray } from '@modules/ui/core/colors';

import { StandRatingsStudentItemEntity } from '@modules/ratings/entities';
import { useTranslation } from '@lib/i18n';
import { CommonHelper, StringHelper } from '@helpers';

type DetailedCompanyRatingsItemProps = {
  company: number;
  students: StandRatingsStudentItemEntity[];
};

const Root = styled.div`
  display: flex;
  padding: 0 80px;
  gap: 40px;
`;

const Company = styled.span`
  font-size: 55px;
  color: ${colors.lightGreenBlue};
`;

const CompanyInfo = styled.div``;

const CompanyText = styled.span`
  font-size: 25px;
  color: ${colors.gray};
  margin-left: 20px;
`;

const StudentList = styled.div`
  font-size: 30px;
  color: #fff;
  padding-top: 20px;
`;

const Item = styled.div`
  margin-bottom: 10px;
`;

const List = styled.div`
  font-size: 25px;
  color: ${colors.gray};

  padding-top: 20px;

  span {
    font-size: 30px;

    color: ${colors.orange};
  }
`;

const DetailedCompanyRatingsItem = (props: DetailedCompanyRatingsItemProps): React.ReactElement => {
  const { company, students } = props;
  const { t } = useTranslation();
  return (
    <Root>
      <CompanyInfo>
        <Company>{company}</Company>
        <CompanyText>{t('Рота')}</CompanyText>
      </CompanyInfo>
      <StudentList>
        {students.map((item, index) => (
          <Item key={index}>{StringHelper.getShortName(item.fullName)}</Item>
        ))}
      </StudentList>
      <List>
        {students.map((item, index) => (
          <Item key={index}>
            <span>{item.platoonX}</span> {t('Взвод')}
          </Item>
        ))}
      </List>
      <List>
        {students.map((item, index) => (
          <Item key={index}>
            <span>{item.score}</span>{' '}
            {CommonHelper.createCountFormatter(item.score, t('score'), false)}
          </Item>
        ))}
      </List>
    </Root>
  );
};

export { DetailedCompanyRatingsItem };
