import { gql } from '@apollo/client';

export const GetStandTopStudentsRatingsQuery = gql`
    query GetStandTopStudentsRatings {
        getStandTopStudentsRatings {
            id
            fullName
            company
            platoonX
            score
        }
    }
`;
