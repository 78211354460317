class CommonHelper {
    static fillDefaultValues<T extends {}>(values: Record<string, any>, initials: T): T {
        let shallowInitials = { ...initials };

        for (const key in shallowInitials) {
            if (typeof values[key] !== 'undefined') {
                shallowInitials[key] = values[key];
            }
        }

        return shallowInitials;
    }

    static createCountFormatter(number: number, words: string[], withNumber = true) {
        const cases = [2, 0, 1, 1, 1, 2];
        const roundNumber = Math.floor(number);

        const string =
            words[
                roundNumber % 100 > 4 && roundNumber % 100 < 20
                    ? 2
                    : cases[roundNumber % 10 < 5 ? roundNumber % 10 : 5]
            ];

        if (withNumber) {
            const displayNumber = Math.floor(number * 10) / 10;

            return displayNumber + ' ' + string;
        }

        return string;
    }
}

export { CommonHelper };
