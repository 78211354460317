import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  width: 240px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  &:before {
    position: absolute;
    content: '';
    display: block;
    height: 400px;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: .8;
    background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, .18) 0%, rgba(255, 255, 255, .0) 75%, rgba(255, 255, 255, 0) 100%);
  }
`

const Inner = styled.div`
  width: 190px;
  
  img {
    width: 100%;
    object-fit: contain;
  }
`

const Logo = (): React.ReactElement => {
    return (
        <Root>
            <Inner>
                <img src='/images/star.gif' alt='logo-start' />
            </Inner>
        </Root>
    );
};

export { Logo };
