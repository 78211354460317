import React from 'react';
import styled from 'styled-components';

import { useTranslation } from '@lib/i18n';

type DashboardCardProps = {
  icon: React.ReactElement;
  children?: React.ReactElement;
};

const Root = styled.div`
  display: flex;
  position: relative;

  background: url(/images/ui/card-row.svg);

  width: 1910px;
  height: 200px;
`;

const Icon = styled.div`
  position: absolute;

  top: 35px;
  left: 25px;
`;

const Content = styled.div`
  position: absolute;

  left: 150px;
`;

const StandRow = (props: DashboardCardProps): React.ReactElement => {
  const { icon, children } = props;

  const { t } = useTranslation();

  return (
    <Root>
      {icon ? <Icon>{icon}</Icon> : null}

      <Content>{children}</Content>
    </Root>
  );
};

export { StandRow };
