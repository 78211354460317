import React from 'react';
import styled from 'styled-components';

import { CommonHelper } from '@helpers';
import { getDayOfWeek, getFormattedLap } from './helpers';

import type { Lap, Date as DateEntity } from '@modules/dashboard/entities';
import { useTranslation, useCurrentLanguage } from '@lib/i18n';
import { colors } from '@modules/ui/core/colors';

type CurrentLapProps = {
  students?: number;
  date?: DateEntity | null;
  lap?: Lap | null;
};

const CardInfoRow = styled.div`
  position: absolute;
  top: 0px;
  left: 332px;
  width: 1487px;
  height: 69px;
  background: url(/images/ui/card-info-row.svg);

  display: flex;
  gap: 40px;

  padding-left: 40px;

  align-items: center;
`;

const CardInfoDivider = styled.div`
  background: ${colors['gray']};

  width: 12px;
  height: 9px;
`;

const CardInfoItem = styled.span`
  color: ${colors['gray']};

  font-size: 25px;
`;

const CurrentLap = (props: CurrentLapProps): React.ReactElement => {
  const { students = 0, date, lap } = props;

  const { t, language } = useTranslation();

  const displayStudents = CommonHelper.createCountFormatter(students, t('students'));
  const displayLapRange = getFormattedLap(lap, language);
  const displayAbout = `${displayStudents}, ${displayLapRange}`;

  const formattedDayOfWeek = getDayOfWeek(date, 'EEEE', language);
  const formattedNumberDayOfWeek = getDayOfWeek(date, 'dd MMMM', language);

  return (
    <CardInfoRow>
      <CardInfoItem>{displayStudents}</CardInfoItem>
      <CardInfoDivider />
      <CardInfoItem>
        {formattedDayOfWeek}, {formattedNumberDayOfWeek}
      </CardInfoItem>
    </CardInfoRow>
  );
};

export { CurrentLap };
