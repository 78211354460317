import React from 'react';
import styled from 'styled-components';

type EmptyLayoutProps = {
    children?: React.ReactElement;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;

const EmptyLayout = (props: EmptyLayoutProps): React.ReactElement => {
    const { children } = props;

    return <Root>{children}</Root>;
};

export { EmptyLayout };
