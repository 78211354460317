import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { SdbInformation } from '@modules/types/graphql';

export type Entity = SdbInformation | null;

export type Date = {
  date: string;
  time: string;
};

export type Weather = {
  temp: number;
  icon: string;
  conditionSlug: string;
  condition: string;
};

export type CompaniesRatingItem = {
  company: number;
  score: number;
};

export type BestStudent = {
  id?: string;
  fullName?: string;
  company?: number;
  platoonX?: number;
  score?: number;
};

export type Lap = {
  from: string;
  to: string;
};

@DecribeEntity('SdbInformationEntity')
class SdbInformationEntity extends BaseEntity {
  date: Date | null;
  weather: Weather | null;
  students: number;
  companiesRating: (CompaniesRatingItem | null)[] | null;
  bestStudent: BestStudent | null;
  lap: Lap | null;

  constructor(entity: Entity) {
    super(entity);

    this.date = entity?.date
      ? {
          date: entity.date.date ?? '',
          time: entity.date.time ?? '',
        }
      : null;

    this.weather = entity?.weather
      ? {
          temp: entity.weather.temp ?? 0,
          icon: entity.weather.icon ?? '',
          conditionSlug: entity.weather.conditionSlug ?? '',
          condition: entity.weather.condition ?? '',
        }
      : null;

    this.students = entity?.students ?? 0;

    this.companiesRating = entity?.companiesRating ?? [];

    this.bestStudent = entity?.bestStudent
      ? {
          id: entity.bestStudent.id ?? '',
          fullName: entity.bestStudent.fullName ?? '',
          company: entity.bestStudent.company ?? 0,
          platoonX: entity.bestStudent.platoonX ?? 0,
          score: entity.bestStudent.score ?? 0,
        }
      : null;

    this.lap = entity?.lap
      ? {
          from: entity.lap.from ?? '',
          to: entity.lap.to ?? '',
        }
      : null;
  }

  getMaxScoreRating() {
    let maxScore = 0;

    if (this.companiesRating && this.companiesRating.length > 0) {
      const entityWithMaxScore = this.companiesRating.reduce((carry, entity) => {
        if (carry && entity && carry.score > entity.score) {
          return carry;
        }

        return entity;
      });

      maxScore = entityWithMaxScore?.score ?? 0;
    }

    return maxScore;
  }
}

export { SdbInformationEntity };
