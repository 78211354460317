import React from 'react';
import styled, { css } from 'styled-components';

type WeatherProps = {
    condition?: string;
    temp?: number;
}

const Root = styled.div`
  display: block;
`

const Degree = styled.div`
  font-size: 2.7em;
`

const Label = styled.div`
  font-size: 1em;
`

const Temp = styled.div<{ withIconDeg?: boolean }>`
  position: relative;

  ${p => !!p.withIconDeg && css`
    &:after {
      content: '°C';
    }
  `}
`;

const YaLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 24px;
  width: 180px;
  
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const Weather = (props: WeatherProps): React.ReactElement => {
    const { condition, temp = 0 } = props;

    return (
        <Root>
            <Degree>
                <Temp withIconDeg>{temp}</Temp>
            </Degree>

            <Label>
                {condition}
            </Label>

            <YaLogo>
                <img src='/images/ya-weather-logo.svg' alt='' />
            </YaLogo>
        </Root>
    );
};

export { Weather };
