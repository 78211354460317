import React from 'react';
import styled from 'styled-components';

import { Loader, Title } from '@modules/layout/moleculas';
import { StandCard } from '@modules/shared/organisms';
import { useSummaryCompanyRatings } from '@modules/ratings/hooks';
import { CompaniesRating } from '@modules/dashboard/organisms';
import { lightBlueGray } from '@modules/ui/core/colors';
import { useTranslation } from '@lib/i18n';

const Root = styled.div`
  display: block;
`;

const List = styled.div`
  margin-top: 40px;

  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const SummaryCompanyRatings = (): React.ReactElement => {
  const {
    summaryCompanyRatings,
    summaryCompanyRatingsExists,
    summaryCompanyRatingsMaxScore,
    loading: summaryCompanyRatingsLoading,
  } = useSummaryCompanyRatings({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  const renderList = React.useMemo(() => {
    if (summaryCompanyRatingsLoading) {
      return <Loader size={40} />;
    }

    return (
      <StandCard title={t('Общий рейтинг')} version={3}>
        <CompaniesRating
          companies={summaryCompanyRatings}
          maxScore={summaryCompanyRatingsMaxScore}
        />
      </StandCard>
    );
  }, [summaryCompanyRatingsLoading]);

  return (
    <Root>
      <List>{renderList}</List>
    </Root>
  );
};

export { SummaryCompanyRatings };
