import { QueryHookOptions, useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { StandCompanyRatingsItemEntityList } from '@modules/ratings/entities';
import { GetStandCompanyRatingsQuery } from '@modules/ratings/graphql';

import type { GetStandCompanyRatingsQueryType } from '@modules/types/graphql';

const useDetailedCompanyRatings = (options: QueryHookOptions<GetStandCompanyRatingsQueryType> = {}) => {
    const queryResult = useQuery<GetStandCompanyRatingsQueryType>(GetStandCompanyRatingsQuery, options);

    const { entities: detailedCompanyRatings, count } = useEntity(
        () => new StandCompanyRatingsItemEntityList(
            queryResult.data?.getStandCompanyRatings ?? null,
            queryResult.data?.getStandCompanyRatings?.length,
        ),
        [queryResult.data],
    );

    const result = {
        detailedCompanyRatings,
        detailedCompanyRatingsExists: count !== 0,
        ...queryResult,
    };

    return result;
};

export { useDetailedCompanyRatings };
