import React from 'react';
import styled from 'styled-components';

import { colors, lightBlueGray } from '@modules/ui/core/colors';

import { Loader } from '@modules/layout/moleculas';
import { StandCard } from '@modules/shared/organisms';
import { useTopStudentsRatings } from '@modules/ratings/hooks';
import { BestStudentPlace } from '@modules/dashboard/atoms';
import { BestStudent } from '@modules/dashboard/organisms';
import { useTranslation } from '@lib/i18n';
import { CommonHelper } from '@helpers';

const List = styled.div`
  margin-top: 40px;
  padding: 0 90px;
  & > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const StudentRow = styled.div`
  background: url(/images/ui/student-row.svg);
  position: relative;
  width: 1681px;
  height: 166px;
`;

const Icon = styled.div`
  position: absolute;
  top: 35px;
  left: 25px;
`;

const Name = styled.div`
  color: #fff;
  font-size: 40px;
  width: 800px;
`;

const Content = styled.div`
  position: absolute;
  left: 180px;

  display: flex;
  gap: 100px;
  align-items: center;
  height: 166px;
`;

const Lap = styled.div`
  font-size: 31px;
  color: #fff;

  position: absolute;

  top: 45px;
  left: 1240px;
`;

const Score = styled.div`
  position: absolute;
  top: 35px;
  left: 1520px;

  color: #fff;
  font-size: 17px;
  text-align: right;

  span {
    display: block;
    margin-bottom: -10px;
    font-size: 55px;
    color: ${colors.orange};
  }
`;

const TopStudentsRatings = (): React.ReactElement => {
  const { topStudentsRatings, loading: topStudentsRatingsLoading } = useTopStudentsRatings({
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation();

  const renderList = React.useMemo(() => {
    if (topStudentsRatingsLoading) {
      return <Loader size={40} />;
    }

    return topStudentsRatings.map((topStudentsRating, index) => (
      <StudentRow key={index}>
        <Icon>
          <BestStudentPlace place={index + 1} />
        </Icon>
        <Content>
          <Name>{topStudentsRating.fullName}</Name>
        </Content>

        <Lap>
          <div>
            {t('Рота')}: {topStudentsRating.company}
          </div>
          <div>
            {t('Взвод')}: {topStudentsRating.platoonX}
          </div>
        </Lap>

        <Score>
          <span>{topStudentsRating.score}</span>
          <div>{CommonHelper.createCountFormatter(topStudentsRating.score, t('score'), false)}</div>
        </Score>
      </StudentRow>
    ));
  }, [topStudentsRatingsLoading]);

  return (
    <StandCard hideLogo version={1} title={t('Лучшие курсанты')}>
      <List>{renderList}</List>
    </StandCard>
  );
};

export { TopStudentsRatings };
