import styled from 'styled-components';
import { white } from '@avangard/ui/colors';
import { colors } from '@modules/ui/core/colors';

type BestStudentPlaceProps = {
  place?: number;
};

const Root = styled.div`
  color: ${colors.darkBlue};
  font-size: 80px;
`;

const BestStudentPlace = (props: BestStudentPlaceProps): React.ReactElement | null => {
  const { place } = props;

  if (!place) {
    return null;
  }

  return <Root>{String(place).length === 1 ? `0${place}` : place}</Root>;
};

export { BestStudentPlace };
