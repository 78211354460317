import { QueryHookOptions, useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { StandSummaryCompanyRatingsItemEntityList } from '@modules/ratings/entities';
import { GetStandSummaryCompanyRatingsQuery } from '@modules/ratings/graphql';

import type { GetStandSummaryCompanyRatingsQueryType } from '@modules/types/graphql';

const useSummaryCompanyRatings = (options: QueryHookOptions<GetStandSummaryCompanyRatingsQueryType> = {}) => {
    const queryResult = useQuery<GetStandSummaryCompanyRatingsQueryType>(GetStandSummaryCompanyRatingsQuery, options);

    const { summaryCompanyRatings, summaryCompanyRatingsExists, summaryCompanyRatingsMaxScore } = useEntity(
        () => {
            const summaryCompanyRatings = new StandSummaryCompanyRatingsItemEntityList(
                queryResult.data?.getStandSummaryCompanyRatings ?? null,
                queryResult.data?.getStandSummaryCompanyRatings?.length,
            )

            const exists = summaryCompanyRatings.exists();
            const maxScore = summaryCompanyRatings.getMaxScore();

            return {
                summaryCompanyRatings: summaryCompanyRatings.entities,
                summaryCompanyRatingsExists: exists,
                summaryCompanyRatingsMaxScore: maxScore,
            };
        },
        [queryResult.data],
    );

    const result = {
        summaryCompanyRatings,
        summaryCompanyRatingsExists,
        summaryCompanyRatingsMaxScore,
        ...queryResult,
    };

    return result;
};

export { useSummaryCompanyRatings };
