import { EntityList } from '@lib/entity';

import { StandEmployeesItemEntity } from './stand-employees-item-entity';

import type { Entity } from './stand-employees-item-entity';

class StandEmployeesItemEntityList extends EntityList<StandEmployeesItemEntity> {
  constructor(items: Entity[] | null, count?: number | null) {
    super(StandEmployeesItemEntity, items, count);
  }
}

export { StandEmployeesItemEntityList };
