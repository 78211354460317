import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { StandRatingsStudentItemEntity, StandRatingsStudentItemEntityList } from '@modules/ratings/entities';

import type { StandCompanyRatingsItem } from '@modules/types/graphql';

export type Entity = StandCompanyRatingsItem | null;

@DecribeEntity('StandCompanyRatingsItemEntity')
class StandCompanyRatingsItemEntity extends BaseEntity {
    company: number

    @RelationList(() => StandRatingsStudentItemEntityList)
    students: StandRatingsStudentItemEntity[]

    constructor(entity: Entity) {
        super(entity);

        this.company = entity?.company ?? 0
    }
}

export { StandCompanyRatingsItemEntity };
