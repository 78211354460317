import React from 'react';
import styled from 'styled-components';

import { Loader } from '@modules/layout/moleculas';
import { StandCard, StandRow, StandTopbar } from '@modules/shared/organisms';
import { useSdbInformation } from '@modules/dashboard/hooks';
import { WeatherIcon, BestStudentPlace } from '@modules/dashboard/atoms';
import {
  DashboardTop,
  CompaniesRating,
  Schedule,
  Weather,
  BestStudent,
  CurrentLap,
} from '@modules/dashboard/organisms';
import { useTranslation } from '@lib/i18n';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 125px;

  & > * {
    flex: 0 0 auto;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }
`;

const DashboardPage = (): React.ReactElement => {
  const { sdbInformation, loading: sdbInformationLoading } = useSdbInformation({
    fetchPolicy: 'network-only',
  });

  const { t } = useTranslation();

  const maxScoreRating = sdbInformation.getMaxScoreRating();
  if (sdbInformationLoading) {
    return <Loader size={512} />;
  }

  return (
    <Root>
      <StandTopbar sdbInformation={sdbInformation} />

      <StandCard version={1} title={t('Заезд')}>
        <CurrentLap
          students={sdbInformation.students}
          date={sdbInformation.date}
          lap={sdbInformation.lap}
        />
        <CompaniesRating companies={sdbInformation?.companiesRating} maxScore={maxScoreRating} />
      </StandCard>

      <StandRow icon={<BestStudentPlace place={1} />}>
        <BestStudent
          fullName={sdbInformation.bestStudent?.fullName}
          company={sdbInformation.bestStudent?.company}
          platoonX={sdbInformation.bestStudent?.platoonX}
          score={sdbInformation.bestStudent?.score}
        />
      </StandRow>
      <StandCard title={t('Расписание')} version={2}>
        <Schedule />
      </StandCard>
    </Root>
  );
};

export { DashboardPage };
