import React from 'react';
import styled from 'styled-components';

import {
  TopStudentsRatings,
  SummaryCompanyRatings,
  DetailedCompanyRatings,
} from '@modules/ratings/organisms';
import { StandTopbar } from '@modules/shared/organisms';
import { useSdbInformation } from '@modules/dashboard/hooks';
import { Loader } from '@modules/layout/moleculas';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 125px;

  & > * {
    flex: 0 0 auto;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }
`;

const RatingsPage = (): React.ReactElement => {
  const { sdbInformation, loading: sdbInformationLoading } = useSdbInformation({
    fetchPolicy: 'network-only',
  });

  if (sdbInformationLoading) {
    return <Loader size={512} />;
  }

  return (
    <Root>
      <StandTopbar sdbInformation={sdbInformation} />

      <TopStudentsRatings />

      <SummaryCompanyRatings />

      <DetailedCompanyRatings />
    </Root>
  );
};

export { RatingsPage };
