import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@lib/i18n';
import { colors } from '@modules/ui/core/colors';

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 35px;
  column-gap: 20px;
  padding: 40px 80px;
`;

const Item = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const ActiveSpan = styled.div`
  width: 300px;
  white-space: nowrap;
  font-size: 45px;
  color: ${colors.lightGreenBlue};
`;

const Text = styled.div`
  font-size: 25px;
  color: #fff;

  max-width: 514px;
`;

const Schedule = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Root>
      <Item>
        <ActiveSpan>7:00</ActiveSpan> <Text>{t('Подъем')}</Text>
      </Item>
      <Item>
        <ActiveSpan>7:10 - 7:30</ActiveSpan> <Text>{t('Утренние мероприятия')}</Text>
      </Item>
      <Item>
        <ActiveSpan>7:30 - 7:50</ActiveSpan>{' '}
        <Text>{t('Утренний туалет, заправка постелей, уборка в жилых помещениях')}</Text>
      </Item>
      <Item>
        <ActiveSpan>7:50 - 8:00</ActiveSpan> <Text>{t('Утренний осмотр, выход на завтрак')}</Text>
      </Item>
      <Item>
        <ActiveSpan>8:00 - 8:25</ActiveSpan> <Text>{t('Завтрак (1 группа: 1-13 взводы)')}</Text>
      </Item>
      <Item>
        <ActiveSpan>8:25 - 8:50</ActiveSpan> <Text>{t('Завтрак (2 группа: 14-25 взводы)')}</Text>
      </Item>
      <Item>
        <ActiveSpan>9:00 - 12:30</ActiveSpan> <Text>{t('Учебные занятия')}</Text>
      </Item>
      <Item>
        <ActiveSpan>12:30 - 13:00</ActiveSpan>{' '}
        <Text>{t('Работа вожатых со взводами, подготовка к обеду')}</Text>
      </Item>
      <Item>
        <ActiveSpan>13:00 - 13:25</ActiveSpan> <Text>{t('Обед (1 группа: 1-13 взводы)')}</Text>
      </Item>
      <Item>
        <ActiveSpan>13:25 - 13:50</ActiveSpan> <Text>{t('Обед (2 группа: 14-25 взводы)')}</Text>
      </Item>
      <Item>
        <ActiveSpan>14:00 - 16:35</ActiveSpan> <Text>{t('Учебные занятия')}</Text>
      </Item>
      <Item>
        <ActiveSpan>16:35 - 17:00</ActiveSpan> <Text>{t('Полдник')}</Text>
      </Item>
      <Item>
        <ActiveSpan>17:00 - 19:00</ActiveSpan> <Text>{t('Вечерние мероприятия')}</Text>
      </Item>
      <Item>
        <ActiveSpan>19:00 - 19:25</ActiveSpan> <Text>{t('Ужин (1 группа: 1-13 взводы)')}</Text>
      </Item>
      <Item>
        <ActiveSpan>19:25 - 19:50</ActiveSpan> <Text>{t('Ужин (2 группа: 14-25 взводы)')}</Text>
      </Item>
      <Item>
        <ActiveSpan>20:00 - 21:00</ActiveSpan> <Text>{t('Вечерние мероприятия')}</Text>
      </Item>
      <Item>
        <ActiveSpan>21:00 - 22:00</ActiveSpan>{' '}
        <Text>
          {t(
            'Сбор взводов в жилых корпусах, подведение итогов дня, второй ужин, подготовка к отбою',
          )}
        </Text>
      </Item>
      <Item>
        <ActiveSpan>22:00</ActiveSpan> <Text>{t('Отбой')}</Text>
      </Item>
    </Root>
  );
};

export { Schedule };
