import { gql } from '@apollo/client';

export const GetStandSummaryCompanyRatingsQuery = gql`
    query GetStandSummaryCompanyRatings {
        getStandSummaryCompanyRatings {
            company
            score
        }
    }
`;
