import { EntityList } from '@lib/entity';

import { StandSummaryCompanyRatingsItemEntity } from './stand-summary-company-ratings-item-entity';

import type { Entity } from './stand-summary-company-ratings-item-entity';

class StandSummaryCompanyRatingsItemEntityList extends EntityList<StandSummaryCompanyRatingsItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(StandSummaryCompanyRatingsItemEntity, items, count);
    }

    getMaxScore() {
        let maxScore = 0;

        if (this.entities.length > 0) {
            const entityWithMaxScore = this.entities.reduce((carry, entity) => {
                if (carry.score > entity.score) {
                    return carry;
                }

                return entity;
            });

            maxScore = entityWithMaxScore?.score ?? 0;
        }

        return maxScore;
    }

    exists() {
        let exists = this.entities.length !== 0;

        const maxScore = this.getMaxScore();

        if (maxScore === 0) {
            exists = Boolean(maxScore);
        }

        return exists;
    }
}

export { StandSummaryCompanyRatingsItemEntityList };
